frappe.provide("frappe.ui.form");


frappe.ui.form.ControlTable = class ControlTable extends frappe.ui.form.ControlTable {
  make() {
		super.make();
    let title = cur_frm.doc.title + " " + cur_frm.doc.name;
    if(this.df.fieldname == "items" && cur_frm.doc.doctype =="Quotation") {
      this.df.label = title
    }
  }
}
